<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Modifier une formation
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiPencil }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
      <v-form>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="course.name"
                label="Nom de la formation"
                outlined
                dense
                placeholder="Nom de la formation"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="course.edofLink"
                label="Lien EDOF de la formation"
                placeholder="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/89071917200029_2461/89071917200029_1234"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="course.duration"
                label="Durée de la formation"
                outlined
                suffix="heures"
                dense
                type="number"
                placeholder="Durée de la formation"
                :hint="`${Number(course.duration)} heures dont ${Number(course.visio)} heures de visio`"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="course.visio"
                type="number"
                :max="course.duration"
                label="Durée du visio"
                outlined
                dense
                suffix="heure(s)"
                placeholder="Durée visio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="course.certification"
                clearable
                :items="certifications"
                item-text="name"
                item-value="_id"
                label="Certification"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-select
                v-model="course.tuteur"
                :items="tuteurs"
                clearable
                item-text="nom"
                item-value="id"
                label="Tuteur"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="course.price"
                label="Prix de la formation"
                type="number"
                outlined
                dense
                suffix="€"
                placeholder="Prix de la formation"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <div
            v-for="(variation, index) in course.variations"
            :key="index"
          >
            <v-divider></v-divider>
            <div class="d-flex justify-space-around">
              <v-spacer></v-spacer>
              <p class="text-h5 text--primary text-md-center mt-3">
                Variation {{ index +1 }}
              </p>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="error"
                @click="removeVariation(index)"
              >
                <v-icon>{{ icons.mdiTrashCan }}</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="course.variations[index].price"
                  :label="`Prix de la variation ${index +1}`"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  placeholder="1200"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="course.variations[index].edofLink"
                  :label="`Lien EDOF de la variation ${index +1}`"
                  placeholder="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/89071917200029_2461/89071917200029_1234"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="course.variations[index].duration"
                  :label="`Durée de la variation ${index+1}`"
                  outlined
                  suffix="heures"
                  dense
                  type="number"
                  placeholder="5"
                  :hint="`${Number(course.variations[index].duration)} heures dont ${Number(course.variations[index].visio)} heures de visio`"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="course.variations[index].visio"
                  type="number"
                  :max="course.variations[index].duration"
                  :label="`Durée du visio de la variation ${index+1}`"
                  outlined
                  dense
                  suffix="heure(s)"
                  placeholder="4"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            outlined
            block
            @click="addVariation"
          >
            Ajouter une variation
          </v-btn>
        </v-card-text>
      </v-form>
      <v-card-actions class="d-flex">
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              color="error"
              dark
              v-bind="attrs"
              outlined
              v-on="on"
            >
              Supprimer la formation
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Attention
            </v-card-title>

            <v-card-text>
              Toute suppression est définitive. <br />
              La suppression d'une formation affectée à des apprenants peut poser problème pour la création de documents et d'autres éléments.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="deleteFormation"
              >
                Supprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="editFormation"
        >
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiPencil, mdiTrashCan } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Modifier une formation',
    titleTemplate: '%s - Jimbo',
  },
  components: {
  },
  data() {
    return {
      selectedStudent: '',
      dialog: false,
      loading: true,
      selectedCert: 0,
      course: {
        name: '',
        duration: '',
        tuteur: [],
        price: 0,
        certification: '',
        variations: [],
      },
      tuteurs: this.$store.getters.getTutors,
      certifications: [],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchFormation(), this.fetchCertifs()])
        .then(values => {
          [this.course, this.certifications] = values
          if (this.course.certification) {
            const matchingCert = this.certifications.find(x => x._id === this.course.certification)
            if (typeof matchingCert === 'undefined') {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'La certification correspondante n\'a pas été trouvée.',
                value: true,
              })
            } else {
              this.selectedCert = matchingCert.id
            }
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchFormation() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/course/${this.$route.params.id}`)

      return response.data
    },
    async fetchCertifs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certification/all/${this.$store.state.company._id}`)

      return response.data
    },

    deleteFormation() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/course/${this.$route.params.id}`)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation supprimée',
            value: true,
          })
          this.$router.push({ name: 'formations' })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression de la formation',
            value: true,
          })
        })
    },
    editFormation() {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/course/${this.$route.params.id}`, this.course)
        .then(res => {
          this.course = res.data
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation mise à jour',
            value: true,
          })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification de la formation',
            value: true,
          })
        })
    },
    addVariation() {
      this.course.variations.push({
        duration: 0,
        price: 0,
        visio: 0,
        edofLink: '',
      })
    },
    removeVariation(index) {
      this.course.variations.splice(index, 1)
    },
  },
}
</script>

<style>

</style>
